import { defineMessages } from 'react-intl'

/**
 * Define Messages for dynamic ids
 */

const messages = defineMessages({
  error: {
    id: 'code',
    defaultMessage: 'Error Code',
  },
  AuthSignUpPhoneNumberAlreadyUsed: {
    id: 'AuthSignUpPhoneNumberAlreadyUsed',
    defaultMessage: 'Phone number already registered',
  },
  NotAuthorizedException: {
    id: 'NotAuthorizedException',
    defaultMessage: 'Not Authorized',
  },
  AuthLoginWrongCredentials: {
    id: 'AuthLoginWrongCredentials',
    defaultMessage: 'Password is incorrect',
  },
  AuthSignUpEmailAlreadyUsed: {
    id: 'AuthSignUpEmailAlreadyUsed',
    defaultMessage: 'E-mail already registered',
  },
  InvalidParameterException: {
    id: 'InvalidParameterException',
    defaultMessage: 'Phone number is not valid',
  },
  AuthOneTimeTokenAlreadyUsed: {
    id: 'AuthOneTimeTokenAlreadyUsed',
    defaultMessage: 'Token has already been used',
  },
  AuthOneTimeTokenExpired: {
    id: 'AuthOneTimeTokenExpired',
    defaultMessage: 'Token has expired',
  },
  DocboxDocumentNameAlreadyExists: {
    id: 'DocboxDocumentNameAlreadyExists',
    defaultMessage: 'File name must be unique',
  },
  ApiInputValidationFailed: {
    id: 'ApiInputValidationFailed',
    defaultMessage: 'Input validation is incorrect',
  },
  AuthNotAuthenticated: {
    id: 'AuthNotAuthenticated',
    defaultMessage: 'Not authenticated',
  },
  NO_IN_PROGRESS_SURVEYS: {
    id: 'NO_IN_PROGRESS_SURVEYS',
    defaultMessage: "You don't have any in progress surveys.",
  },
  NO_COMPLETED_SURVEYS: {
    id: 'NO_COMPLETED_SURVEYS',
    defaultMessage: "You don't have any completed surveys.",
  },
  AuthLoginMfaCodeInvalid: {
    id: 'AuthLoginMfaCodeInvalid',
    defaultMessage: 'Code is invalid.',
  },
  AuthLoginMfaCodeExpired: {
    id: 'AuthLoginMfaCodeExpired',
    defaultMessage: 'Code expired.',
  },
  LAST_30_DAYS: {
    id: 'LAST_30_DAYS',
    defaultMessage: 'Last 30 days',
  },
  LAST_60_DAYS: {
    id: 'LAST_60_DAYS',
    defaultMessage: 'Last 60 days',
  },
  LAST_90_DAYS: {
    id: 'LAST_90_DAYS',
    defaultMessage: 'Last 90 days',
  },
  SHOWING_RESPONDENTS_FILTER: {
    id: 'SHOWING_RESPONDENTS_FILTER',
    defaultMessage:
      'Showing {numRespondents} who answered {valueClickedOn} as {colClickedInTitle}',
  },
  SHOWING_RESPONDENTS_FILTER_2: {
    id: 'SHOWING_RESPONDENTS_FILTER_2',
    defaultMessage:
      "Showing {numRespondents} who's {colClickedInTitle} is {valueClickedOn}",
  },
  CompanyNameAlreadyUsed: {
    id: 'CompanyNameAlreadyUsed',
    defaultMessage: 'Company name already used',
  },
  INVITE_LINK_EXPIRED_TITLE: {
    id: 'INVITE_LINK_EXPIRED_TITLE',
    defaultMessage: 'This link has expired',
  },
  INVITE_LINK_EXPIRED_SUBTITLE_PT1: {
    id: 'INVITE_LINK_EXPIRED_SUBTITLE_PT1',
    defaultMessage:
      'For security reasons, invite links are only valid for 24hrs. You can request a new link by clicking below.',
  },
  NEXT: {
    id: 'NEXT',
    defaultMessage: 'Next',
  },
  SAVING: {
    id: 'SAVING',
    defaultMessage: 'Saving',
  },
  SUBMIT: {
    id: 'SUBMIT',
    defaultMessage: 'Submit',
  },
  INVITE_LINK_ALREADY_ACTIVE_SUBTITLE: {
    id: 'INVITE_LINK_ALREADY_ACTIVE_SUBTITLE',
    defaultMessage:
      "Click below to login. If you haven't changed your password yet, use the temporary password in the e-mail",
  },
  INVITE_LINK_ALREADY_ACTIVE_SUBTITLE_ADMIN: {
    id: 'INVITE_LINK_ALREADY_ACTIVE_SUBTITLE_ADMIN',
    defaultMessage: 'Click below to login.',
  },
  INVITE_LINK_ALREADY_USED_TITLE: {
    id: 'INVITE_LINK_ALREADY_USED_TITLE',
    defaultMessage: 'This invite link has already been used.',
  },
  INVITE_LINK_ALREADY_USED_SUBTITLE: {
    id: 'INVITE_LINK_ALREADY_USED_SUBTITLE',
    defaultMessage:
      'This may be an old link which is no longer valid. You can request a new link by clicking below.',
  },
  UNEXPECTED_ERROR: {
    id: 'UNEXPECTED_ERROR',
    defaultMessage: 'Unexpected error. Refresh the page and try again',
  },
  PENDING: {
    id: 'PENDING',
    defaultMessage: 'Pending',
  },
  ACTIVE_2: {
    id: 'ACTIVE_2',
    defaultMessage: 'Active',
  },
  DEACTIVATED: {
    id: 'DEACTIVATED',
    defaultMessage: 'Deactivated',
  },
  NO_RESPONSES_TABLE_MESSAGE: {
    id: 'NO_RESPONSES_TABLE_MESSAGE',
    defaultMessage: 'It appears no respondents have completed the survey yet',
  },
  SELECTED_RESPONDENT_HAS_NOT_ANSWERED: {
    id: 'SELECTED_RESPONDENT_HAS_NOT_ANSWERED',
    defaultMessage:
      'It appears the selected respondent has not completed the survey yet',
  },
  DRAFT: {
    id: 'DRAFT',
    defaultMessage: 'Draft',
  },
  ACTIVE: {
    id: 'ACTIVE',
    defaultMessage: 'Active',
  },
  SCHEDULED: {
    id: 'SCHEDULED',
    defaultMessage: 'Scheduled',
  },
  FINAL: {
    id: 'FINAL',
    defaultMessage: 'Final',
  },
  DEPARTMENT: {
    id: 'DEPARTMENT',
    defaultMessage: 'Department',
  },
  BUSINESS_PROCESS: {
    id: 'BUSINESS_PROCESS',
    defaultMessage: 'Business Process',
  },
  // delete when process step is updated
  PROCESS: {
    id: 'PROCESS',
    defaultMessage: 'Business Process',
  },
  LEGAL_BASIS: {
    id: 'LEGAL_BASIS',
    defaultMessage: 'Legal Basis',
  },
  TYPE_OF_PROCESSING: {
    id: 'TYPE_OF_PROCESSING',
    defaultMessage: 'Type of Processing',
  },
  DATA_SUBJECT: {
    id: 'DATA_SUBJECT',
    defaultMessage: 'Data Subject',
  },
  TOOLS_OR_ASSETS: {
    id: 'TOOLS_OR_ASSETS',
    defaultMessage: 'Tools or Assets',
  },
  DATA_ELEMENTS: {
    id: 'DATA_ELEMENTS',
    defaultMessage: 'Data Element(s)',
  },
  SOURCE_OF_DATA: {
    id: 'SOURCE_OF_DATA',
    defaultMessage: 'Source of Data',
  },
  DATA_OWNERSHIP: {
    id: 'DATA_OWNERSHIP',
    defaultMessage: 'Data Ownership',
  },
  DATA_LIFECYCLE: {
    id: 'DATA_LIFECYCLE',
    defaultMessage: 'Data Lifecycle',
  },
  CHANGE_SUBSCRIPTION_WARNING: {
    id: 'CHANGE_SUBSCRIPTION_WARNING',
    defaultMessage: 'You’re about to change your subscription!',
  },
  NO_REQUESTS: {
    id: 'NO_REQUESTS',
    defaultMessage: "You don't have any requests.",
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    defaultMessage: 'In Progress',
  },
  OPEN: {
    id: 'OPEN',
    defaultMessage: 'Open',
  },
  ON_HOLD: {
    id: 'ON_HOLD',
    defaultMessage: 'On Hold',
  },
  COMPLETED: {
    id: 'COMPLETED',
    defaultMessage: 'Completed',
  },
  CANCELLED: {
    id: 'CANCELLED',
    defaultMessage: 'Cancelled',
  },
  'search.modules.item.label.sent': {
    id: 'search.modules.item.label.sent',
    defaultMessage: 'Sent',
  },
  'search.modules.item.label.due': {
    id: 'search.modules.item.label.due',
    defaultMessage: 'Due',
  },
  'search.modules.item.label.responded': {
    id: 'search.modules.item.label.responded',
    defaultMessage: 'Responded',
  },
  'search.modules.item.label.created': {
    id: 'search.modules.item.label.created',
    defaultMessage: 'Created',
  },
  'search.modules.item.label.questions': {
    id: 'search.modules.item.label.questions',
    defaultMessage: 'Questions',
  },
  'search.modules.item.label.added': {
    id: 'search.modules.item.label.added',
    defaultMessage: 'Added',
  },
  'search.modules.item.label.updated': {
    id: 'search.modules.item.label.updated',
    defaultMessage: 'Updated',
  },
  'search.modules.item.label.idVerified': {
    id: 'search.modules.item.label.idVerified',
    defaultMessage: 'ID Verified',
  },
  'tasks.status.archive.title': {
    id: 'tasks.status.archive.title',
    defaultMessage: 'Archive',
  },
  CLOSED: {
    id: 'CLOSED',
    defaultMessage: 'Closed',
  },
  DONE: {
    id: 'DONE',
    defaultMessage: 'Done',
  },
  'page.head.title.admin.library': {
    id: 'page.head.title.admin.library',
    defaultMessage: '{companyName} Privacy Library | Palqee',
  },
  'page.head.title.respondents.library': {
    id: 'page.head.title.respondents.library',
    defaultMessage: '{companyName} Privacy Library | Palqee',
  },
  'page.head.title.settings.company': {
    id: 'page.head.title.settings.company',
    defaultMessage: '{companyName} Settings | Palqee',
  },
  'page.head.title.settings.profile': {
    id: 'page.head.title.settings.profile',
    defaultMessage: '{companyName} User Preferences | Palqee',
  },
  'page.head.title.respondent.dashboard': {
    id: 'page.head.title.respondent.dashboard',
    defaultMessage: '{companyName} Privacy Hub | Palqee',
  },
  'page.head.title.dsar.forms': {
    id: 'page.head.title.dsar.forms',
    defaultMessage: '{companyName} DSAR Forms | Palqee',
  },
  'page.head.title.dsar.requests': {
    id: 'page.head.title.dsar.requests',
    defaultMessage: '{companyName} DSAR Requests | Palqee',
  },
  'page.head.title.respondent.survey.wizard': {
    id: 'page.head.title.respondent.survey.wizard',
    defaultMessage: '{companyName} {surveyName} | Palqee',
  },
  'page.head.title.respondent.survey.answers': {
    id: 'page.head.title.respondent.survey.answers',
    defaultMessage: '{surveyName} Answers | Palqee',
  },
  'page.head.title.tasks': {
    id: 'page.head.title.tasks',
    defaultMessage: '{companyName} Tasks | Palqee',
  },
  'page.head.title.tutorials': {
    id: 'page.head.title.tutorials',
    defaultMessage: 'Video Tutorials | Palqee',
  },
  'page.head.title.admin.audiences': {
    id: 'page.head.title.admin.audiences',
    defaultMessage: '{companyName} Audiences | Palqee',
  },
  'page.head.title.admin.builder': {
    id: 'page.head.title.admin.builder',
    defaultMessage: '{companyName} Builder | Palqee',
  },
  'page.head.title.admin.manager': {
    id: 'page.head.title.admin.manager',
    defaultMessage: '{companyName} Privacy Manager | Palqee',
  },
  'page.head.title.admin.summary': {
    id: 'page.head.title.admin.summary',
    defaultMessage: '{surveyName} Summary | Palqee',
  },
  'page.head.title.login': {
    id: 'page.head.title.login',
    defaultMessage: 'Login | Palqee',
  },
  'page.head.title.signup': {
    id: 'page.head.title.signup',
    defaultMessage: 'Sign-up | Palqee',
  },
  'tasks.activity.task.create': {
    id: 'tasks.activity.task.create',
    defaultMessage: 'created this task',
  },
  'tasks.activity.task.title': {
    id: 'tasks.activity.task.title',
    defaultMessage: 'updated task title',
  },
  'tasks.activity.category.add': {
    id: 'tasks.activity.category.add',
    defaultMessage: 'added category',
  },
  'tasks.activity.category.remove': {
    id: 'tasks.activity.category.remove',
    defaultMessage: 'removed category',
  },
  'tasks.activity.status.update': {
    id: 'tasks.activity.status.update',
    defaultMessage: 'updated status from',
  },
  'tasks.activity.priority.update': {
    id: 'tasks.activity.priority.update',
    defaultMessage: 'updated priority from',
  },
  'tasks.activity.task.archive': {
    id: 'tasks.activity.task.archive',
    defaultMessage: 'archived this task',
  },
  'task.activity.assignee.user_add': {
    id: 'task.activity.assignee.user_add',
    defaultMessage: 'assigned to user',
  },
  'task.activity.assignee.user_remove': {
    id: 'task.activity.assignee.user_remove',
    defaultMessage: 'unassigned user',
  },
  'task.activity.assignee.audience_add': {
    id: 'task.activity.assignee.audience_add',
    defaultMessage: 'assigned to audience',
  },
  'task.activity.assignee.audience_remove': {
    id: 'task.activity.assignee.audience_remove',
    defaultMessage: 'unassigned audience',
  },
  'task.activity.deadlineAt.add': {
    id: 'task.activity.deadlineAt.add',
    defaultMessage: 'added due date',
  },
  'task.activity.deadlineAt.update': {
    id: 'task.activity.deadlineAt.update',
    defaultMessage: 'updated due date',
  },
  'task.activity.deadlineAt.remove': {
    id: 'task.activity.deadlineAt.remove',
    defaultMessage: 'removed due date',
  },
  'task.activity.links.add': {
    id: 'task.activity.links.add',
    defaultMessage: 'added link request',
  },
  'task.activity.links.remove': {
    id: 'task.activity.links.remove',
    defaultMessage: 'removed link request',
  },
  'task.activity.description.add': {
    id: 'task.activity.description.add',
    defaultMessage: 'added description',
  },
  'task.activity.description.update': {
    id: 'task.activity.description.update',
    defaultMessage: 'updated the description to',
  },
  'task.activity.description.delete': {
    id: 'task.activity.description.delete',
    defaultMessage: 'deleted description',
  },
  'task.activity.attach.add': {
    id: 'task.activity.attach.add',
    defaultMessage: 'added attachment',
  },
  'task.activity.attach.remove': {
    id: 'task.activity.attach.remove',
    defaultMessage: 'removed attachment',
  },
  'task.activity.comment.add': {
    id: 'task.activity.comment.add',
    defaultMessage: 'added comment',
  },
  'tasks.status.settings.error.delete_status_with_tasks': {
    id: 'tasks.status.settings.error.delete_status_with_tasks',
    defaultMessage: 'can not delete the statuses as tasks are associated',
  },
  employee: {
    id: 'employee',
    defaultMessage: 'Employee',
  },
  vendor: {
    id: 'vendor',
    defaultMessage: 'Vendor',
  },
  role_process_owner: {
    id: 'role_process_owner',
    defaultMessage: 'process owner',
  },
  role_vendor: {
    id: 'role_vendor',
    defaultMessage: 'vendor',
  },
  AuthSignUpEmailIncorrect: {
    id: 'AuthSignUpEmailIncorrect',
    defaultMessage: 'Email address incorrect format',
  },
  'audiences.bulk_upload.label.email': {
    id: 'audiences.bulk_upload.label.email',
    defaultMessage: 'Email',
  },
  'audiences.bulk_upload.label.role': {
    id: 'audiences.bulk_upload.label.role',
    defaultMessage: 'Role',
  },
  'audiences.bulk_upload.label.department': {
    id: 'audiences.bulk_upload.label.department',
    defaultMessage: 'Department',
  },
  'audiences.bulk_upload.label.first_name': {
    id: 'audiences.bulk_upload.label.first_name',
    defaultMessage: 'First Name',
  },
  'audiences.bulk_upload.label.last_name': {
    id: 'audiences.bulk_upload.label.last_name',
    defaultMessage: 'Last Name',
  },
  'audiences.bulk_upload.label.company_name': {
    id: 'audiences.bulk_upload.label.company_name',
    defaultMessage: 'Company Name',
  },
  'audiences.bulk_upload.errors.not_valid': {
    id: 'audiences.bulk_upload.errors.not_valid',
    defaultMessage: 'The file contains e-mails with invalid format',
  },
  'audiences.bulk_upload.errors.duplicates': {
    id: 'audiences.bulk_upload.errors.duplicates',
    defaultMessage: 'The file contains dupilcated e-mails',
  },
  'audiences.bulk_upload.errors.not_valid_and_duplicates': {
    id: 'audiences.bulk_upload.errors.not_valid_and_duplicates',
    defaultMessage:
      'The file contains e-mails with invalid format and duplicated e-mails',
  },
  'audiences.bulk_upload.errors.not_valid_and_duplicates_and_required': {
    id: 'audiences.bulk_upload.errors.not_valid_and_duplicates_and_required',
    defaultMessage:
      'The file contains e-mails with invalid format, duplicated e-mails and missing required values',
  },
  'audiences.bulk_upload.errors.not_valid_and_required': {
    id: 'audiences.bulk_upload.errors.not_valid_and_required',
    defaultMessage:
      'The file contains e-mails with invalid format and missing required values',
  },
  'audiences.bulk_upload.errors.duplicates_and_required': {
    id: 'audiences.bulk_upload.errors.duplicates_and_required',
    defaultMessage:
      'The file contains duplicated e-mails and missing required values',
  },
  'audiences.bulk_upload.errors.required': {
    id: 'audiences.bulk_upload.errors.required',
    defaultMessage: 'The file contains missing required values',
  },
  'audiences.bulk_upload.label.not_used': {
    id: 'audiences.bulk_upload.label.not_used',
    defaultMessage: 'Not used',
  },
  'audiences.bulk_upload_prepare.upload.error': {
    id: 'audiences.bulk_upload_prepare.upload.error',
    defaultMessage: 'There was an issue with the file upload please try again',
  },
  'audiences.bulk_upload.errors.duplicate_mappings': {
    id: 'audiences.bulk_upload.errors.duplicate_mappings',
    defaultMessage: 'Please ensure all mappings are unique',
  },
  'audiences.bulk_upload.errors.missing_required_mappings': {
    id: 'audiences.bulk_upload.errors.missing_required_mappings',
    defaultMessage: 'Please match all required fields before proceeding',
  },
  'task.activity.startAt.add': {
    id: 'task.activity.startAt.add',
    defaultMessage: 'added start date',
  },
  'task.activity.startAt.update': {
    id: 'task.activity.startAt.update',
    defaultMessage: 'updated start date',
  },
  'task.activity.startAt.delete': {
    id: 'task.activity.startAt.delete',
    defaultMessage: 'removed start date',
  },
  'dsar.history_log.email_message': {
    id: 'dsar.history_log.email_message',
    defaultMessage: '{user} sent {dataSubject} an email message',
  },
  'dsar.history_log.created': {
    id: 'dsar.history_log.created',
    defaultMessage:
      '{user} made a {regulationName} access request to {companyName}',
  },
  'dsar.history_log.status_change': {
    id: 'dsar.history_log.status_change',
    defaultMessage:
      '{user} changed the status of this request from {oldStatus} to {newStatus}',
  },
  'dsar.history_log.id_verified': {
    id: 'dsar.history_log.id_verified',
    defaultMessage: '{user} marked the ID as {isVerified}',
  },
  'dsar.history_log.comment': {
    id: 'dsar.history_log.comment',
    defaultMessage: '{user} added a comment',
  },
  'dsar.history_log.email_message_requester': {
    id: 'dsar.history_log.email_message_requester',
    defaultMessage: '{user} sent an email message',
  },
  'page.head.title.consent.forms': {
    id: 'page.head.title.consent.forms',
    defaultMessage: '{companyName} Consent Form | Palqee',
  },
  es: {
    id: 'es',
    defaultMessage: 'Spanish',
  },
  pt: {
    id: 'pt',
    defaultMessage: 'Português',
  },
  de: {
    id: 'de',
    defaultMessage: 'German',
  },
  en: {
    id: 'en',
    defaultMessage: 'English',
  },
  'page.head.title.consent.manager': {
    id: 'page.head.title.consent.manager',
    defaultMessage: '{companyName} Consent Manager | Palqee',
  },
  'page.head.title.datamap.ropa': {
    id: 'page.head.title.datamap.ropa',
    defaultMessage: '{companyName} Data Map Ropa | Palqee',
  },
  'page.head.title.settings.security': {
    id: 'page.head.title.settings.security',
    defaultMessage: '{companyName} Settings | Palqee',
  },
  'dsar.form.submit.success': {
    id: 'dsar.form.submit.success',
    defaultMessage: 'Dsar form has been successfully {type}',
  },
  'dsar.form.submit.draft.success': {
    id: 'dsar.form.submit.draft.success',
    defaultMessage: 'Dsar draft form has been successfully {type}',
  },
  CREATE_WEBLINK: {
    id: 'CREATE_WEBLINK',
    defaultMessage: 'Publish',
  },
  UPDATE: {
    id: 'UPDATE',
    defaultMessage: 'Update',
  },
  JANUARY: { id: 'JANUARY', defaultMessage: 'January' },
  FEBRUARY: { id: 'FEBRUARY', defaultMessage: 'February' },
  MARCH: { id: 'MARCH', defaultMessage: 'March' },
  APRIL: { id: 'APRIL', defaultMessage: 'April' },
  MAY: { id: 'MAY', defaultMessage: 'May' },
  JUNE: { id: 'JUNE', defaultMessage: 'June' },
  JULY: { id: 'JULY', defaultMessage: 'July' },
  AUGUST: { id: 'AUGUST', defaultMessage: 'August' },
  SEPTEMBER: { id: 'SEPTEMBER', defaultMessage: 'September' },
  OCTOBER: { id: 'OCTOBER', defaultMessage: 'October' },
  NOVEMBER: { id: 'NOVEMBER', defaultMessage: 'November' },
  DECEMBER: { id: 'DECEMBER', defaultMessage: 'December' },
})

export { messages as defineMessages }
